<template>
    <div class="form">
        <Toast />
        <div class="header">
            <h1>Upload de documentos</h1>
        </div>
        
        <Pagination :value="5" :size="5" />

        <div class="limit">
            <img class="center" src="../../../../assets/images/accounts/show.png" alt="" />

            <p class="text-code mt-20">Arraste ou anexe uma foto da frente do seu RG ou CNH.</p>
            <div class="flex">
                <Front @saveUrl="saveUrl" />
                <Back @saveUrl="saveUrl" />
            </div>
            <p class="text-code mt-20">Envie um comprovante de residência clicando na imagem abaixo.</p>
            <p class="text-code">Arquivos suportados: JPG, PNG e PDF</p>
            <ResidenceProof @saveUrl="saveUrl" />
            <p class="text-code mt-20">Escolha o arquivo e certifique-se de que todas as informações estejam nítidas no arquivo.</p>
            <div class="flex w-full justify-content-center mt-2">
                <Button class="btn-submit" :disabled="!uploadComplete.status" label="Continuar" @click="submit" />
            </div>
        </div>
        <DialogRegisterSuccess v-model:visible="visible" />
    </div>
</template>

<script>
import { onMounted, ref, reactive, watch, computed } from "vue";
import { useToast } from "primevue/usetoast";
import Pagination from "../../components/Pagination";
import Front from "./components/Front";
import Back from "./components/Back";
import ResidenceProof from "./components/ResidenceProof";
import DialogRegisterSuccess from "./DialogRegisterSuccess";

import { useRouter } from "vue-router";
// import { 
//     Register,
// } from "./actions";

export default {
    //   props: ["",],
    components: {
        Pagination,
        Front,
        Back,
        ResidenceProof,
        DialogRegisterSuccess,
    },
    setup(props, { emit }) {
        const visible = ref(false);
        let router = useRouter();
        const url_front = ref("");
        const url_back = ref("");
        const url_residence = ref("");
        const toast = useToast();

        const uploadComplete = computed(() => {
            if(url_front.value && url_back.value && url_residence.value){
                return { status: true, class: '' };
            }

            return {status: false, class: ''};
        })

        const submit = async () => {    	
            if (uploadComplete) {
                visible.value = true;
                // router.push("/account/login");
            } else {
                toast.add({
                    severity: "error",
                    summary: "Arquivos Obrigatórios",
                    detail: "É necessário fazer upload de todos os 3 arquivos.",
                    life: 3000,
                });
            }
        };

        const saveUrl = ( url, model ) => {
            if(model === 'front'){
                url_front.value = url;
            }else if (model === 'back'){
                url_back.value = url;
            }else if(model === 'residence'){
                url_residence.value = url;
            }
        }
    

        return {
            submit,
            saveUrl,
            visible,
            uploadComplete,
        }
    },
};
</script>


<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 478px;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;

    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header{
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;
        h1{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }
    .limit{
        width: 100% !important;
        padding: 0 32px;

        .text-code {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
        }
    }

    .center{
        display: block;
        margin: auto;
        margin-right: auto;
    }
}

@media (max-width: 580px){
    .form{
        width: 100%;
    }
}

::v-deep(.p-button) {
    margin-top: 10px;
    padding: 20px 80px;
    width: max-content;
}
</style>