<template>
    <div class="residenceproof-card mt-16">
        <Toast />
        <div
            @dragenter.prevent="toggle"
            @dragleave.prevent="toggle"
            @dragover.prevent
            @drop.prevent="drop"
            @change="selectDocument"
            class="dropzone"
        >         
            <label for="filesresidenceproof">
                <img v-if="hasFile" src="../../../../../assets/images/accounts/residence_accept.png" alt="" />
                <img v-else src="../../../../../assets/images/accounts/residence.png" alt="" />
                <!-- <div class="file-name">
                    <p class="text" v-if="hasFile">
                        {{ files.name }}
                    </p>
                    <p class="text" v-else>Verso</p>
                </div> -->
            </label>   
            <input
              type="file"
              id="filesresidenceproof"
              class="dropzoneFile"
              accept=".png, .jpeg, .jpg, .png, .pdf"
            />
            <i 
                class="accept pi pi-spin pi-spinner mt-2" 
                style="color: black; font-size: 1.75rem; font-weight: bolder;"  
                v-if="loading"
            />
            <img 
                v-if="hasFile && !loading" 
                class="accept" 
                src="../../../../../assets/images/accounts/accept.png"
                alt=""
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import axios from "axios";

const token = localStorage.getItem("token");
const props = defineProps(["modelValue", "msgErros"]);
const emit = defineEmits(["update:modelValue", "saveUrl"]);

const loading = ref(false);
const files = ref("");
const hasFile = ref(false);
const active = ref(false);
const docTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const toast = useToast();

const toggle = () => active.value = !active.value;

const maxSizeFile = (number) => {
    return number <= 50000000;
};

const drop = async (e) => {
    active.value = !active.value;
    files.value = e.dataTransfer.files[0];
    const type = docTypes.includes(files.value.type);
    const maxSize = maxSizeFile(files.value.size);

    if (maxSize && type) {
        const result = await saveFile(files.value, "proof_of_residence");
        if (result) {
            hasFile.value = result;
        } else {
            files.value = "";
            toast.add({
                severity: "error",
                summary: "Atenção!",
                detail: "Erro ao gravar o arquivo no sistema",
                life: 3000,
            });
        }
    } else {
        files.value = "";
        toast.add({
            severity: "error",
            summary: "Atenção!",
            detail: "Arquivo inválido",
            life: 3000,
        });
    }
};

const selectDocument = async (e) => {
    active.value = !active.value;

    if (!e.target.files) {
        files.value = files.value;
    } else {
        files.value = e.target.files[0];
        const type = docTypes.includes(files.value.type);
        const maxSize = maxSizeFile(files.value.size);
        
        if (maxSize && type) {
            const result = await saveFile(files.value, "proof_of_residence");
            if (result) {
                active.value = !active.value;
                hasFile.value = result;
            } else {
                active.value = !active.value;
                files.value[0] = "";
                toast.add({
                    severity: "error",
                    summary: "Atenção!",
                    detail: "Erro ao gravar o arquivo no sistema",
                    life: 3000,
                });
            }
        } else {
            files.value[0] = "";
            toast.add({
                severity: "error",
                summary: "Atenção!",
                detail: "Arquivo inválido",
                life: 3000,
            });
        }
    }
};

const saveFile = async (file, type) => {
    loading.value = true;
    const formData = new FormData();
    formData.append("file", file);
    const url = process.env.VUE_APP_FILE_API_URL + "/patient/" + type;
    
    const response = await axios.post(url, formData, {
        headers: {
            "Access-Control": "Allow-Origin",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
        },
    });

    loading.value = false;

    if (response.status == 200) {
        emit("saveUrl", response.data.url, 'residence')
        return true;
    } else {
        return false;
    }
};
</script>


<style lang="scss" scoped>
.residenceproof-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% / 2);
    font-family: "Roboto", sans-serif;
    cursor: pointer !important;
    margin: 0 auto;
    .accept{
        position: absolute;
        top: 70px;
        right: 10px;
    }
    .text {
        font-size: 14px;
        line-height: 22px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: #828282;
        text-align: center;
        margin: 0 auto;
        margin-top: -20px;
    }
    .dropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        transition: 0.3s ease all;
        label {
            // padding: 8px 12px;
            // color: #fff;
            // background-color: #FF6A33;
            transition: 0.3s ease all;
            border-radius: 8px;
            cursor: pointer;
        }
        input {
            display: none;
        }
    }
}
</style>