<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
        zIndex: 2000,
    }" :baseZIndex="2" :autoZIndex="false" :visible="visible" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Cadastro finalizado com sucesso!</h5>
        </template>

        <div class="content">
            <p>Parabéns, você concluiu seu cadastro com sucesso.Agora você já pode utilizar a nossa plataforma e todos os nossos serviços</p>
            <Button label="Acessar a plataforma" class="addService-button" @click="onSubmit" />
        </div>
    </Dialog>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from "vue-router";

export default {
    props: ["visible", "data"],
    emits: ["update:visible"],
    setup(_, { emit }) {
        let router = useRouter();
        const close = () => emit("update:visible", false)
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            close();
            emit("update:visible", false);
            // emit("closeall", true);
        }

        const onSubmit = () => {
            // after verify bug router.push no focus input
            // router.push("/account/login")
            window.location.href = "/account/login";
        }

        return {
            close,
            onUpdate,
            CloseAll,
            onSubmit,
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 1.5rem;
    margin-bottom: -10px;
    z-index: 99999999999 !important;

    p {
        max-width: 300px;
        text-align: center;
        margin: 0 auto;
        color: #828282;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;

        .btn-cancel {
            background: white;
            border: 1px solid white;
            box-shadow: none;
            color: #FF4500;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            width: 100%;
            padding: 10px;
            cursor: pointer;
            color: #848483;

            &:hover {
                background: #fafafa;
            }
        }
    }
}

.mt-16 {
    margin-top: 16px !important;
}

::v-deep(button) {
    width: max-content;
    padding: 0 20px;
    height: 40px;
}

::v-deep(.p-dialog) {
    z-index: 2303 !important;
}
</style>
